<template>
  <div>
    <div class="d-flex mb-3">
      <TInputDateTimeRange
        :value.sync="dateRange"
        @update:value="setUpdatedAtFilter"
      />
      <TButton
        content="Export"
        icon="cid-cloud-download"
        size="sm"
        class="ml-2"
        @click="exportExcel"
        :disabled="exporting"
      />
      <SButtonCreateOrder class="ml-auto" to="purchases/new" />
    </div>
    <TTableAdvance
      :items="purchases"
      :fields="fields"
      store="order.order_purchase"
      @click-clear-filter="clearFilter"
      reloadable
      @click-reload="refresh"
      resource="purchases"
      enterable
    >
      <template #first_item="{ item }">
        <td>
          <SCardProductItems
            v-if="item.first_item"
            :item="item.first_item.product"
            :itemsCount="item.items_count"
            resource="goods/products"
          />
          <TMessageNotFound v-else />
        </td>
      </template>
      <template #status="{ item }">
        <td>
          <SMessageOrderStatus
            store="order.purchase_statuses"
            :id="item.status_id"
          />
        </td>
      </template>
      <template #supplier="{ item }">
        <td>
          <TLink
            v-if="item.supplier"
            class="text-truncate-0"
            :content="item.supplier.name"
            :to="lodash.getReferenceLink('supplier', item.supplier.id)"
          />
          <TMessageNotFound v-else :slug="item.supplier_id" />
        </td>
      </template>
      <template #trackings="{ item }">
        <td>
          <SMessageTracking
            v-if="!lodash.isEmpty(item.trackings)"
            :value="item.trackings[0].code"
            :checked="item.trackings[0].received"
          />
          <TMessageNotFound v-else />
          <TMessage
            v-if="item.trackings.length > 1"
            content="Tracking"
            lowercase
            size="small"
            color="muted"
            :truncate="1"
          >
            <template #prefix> +{{ item.trackings.length - 1 }} </template>
          </TMessage>
        </td>
      </template>
      <template #buyer_id="{ item }">
        <td>
          <TLink
            class="text-truncate-0"
            v-if="item.buyer"
            :content="item.buyer.name"
            :to="lodash.getReferenceLink('user', item.buyer_id)"
          />
        </td>
      </template>
      <template #balance="{ item }">
        <td>
          <TMessageMoney :amount="item.balance" :currency="item.currency_id" />
        </td>
      </template>
      <template #created_at="{ item }">
        <td>
          <TMessageDateTime :content="item.created_at" small />
        </td>
      </template>
      <template #id-filter>
        <TInputText
          :value.sync="filter.id"
          @update:value="filterChange"
          placeholder="Order Id"
        />
      </template>
      <template #first_item-filter>
        <TInputText
          :value.sync="filter['items.product_id']"
          @update:value="filterChange"
          placeholder="Jancode"
        />
      </template>
      <template #trackings-filter>
        <TInputText
          :value.sync="filter['trackings.code']"
          @update:value="filterChange"
          placeholder="Tracking Id"
        />
      </template>
      <template #supplier-filter>
        <SSelectSupplier
          :value.sync="filter.supplier_id"
          @change="filterChange"
          noCustomLabel
          prependAll
        />
      </template>
      <template #buyer_id-filter>
        <SSelectCustomer
          :value.sync="filter.buyer_id"
          @change="filterChange"
          noCustomLabel
          prependAll
        />
      </template>
      <template #status-filter>
        <SSelectOrderStatus
          store="order.purchase_statuses"
          :value.sync="filter.status_id"
          @change="filterChange"
        />
      </template>
      <template #created_at-filter>
        <TInputDateTimeRange
          :value.sync="dateRange"
          @update:value="setUpdatedAtFilter"
        />
      </template>
    </TTableAdvance>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px" },
        { key: "id", label: "Order Id" },
        {
          key: "trackings",
          label: "Tracking",
          _style: "width: 130px; min-width: 130px",
        },
        {
          key: "status",
          label: "Status",
          _classes: "text-nowrap",
          _style: "width: 200px; min-width: 200px",
        },
        { key: "first_item", label: "Product" },
        {
          key: "supplier",
          label: "Supplier",
          _classes: "",
          _style: "width: 250px; min-width: 250px",
        },
        {
          key: "buyer_id",
          label: "Buyer",
          _classes: "",
          _style: "width: 220px; min-width: 220px",
        },
        { key: "balance", label: "Purchase cost", sorter: true },
        {
          key: "created_at",
          label: "Created at",
          sorter: true,
          _style: "width: 220px",
        },
      ],
      filter: {},
      filter_between: {},
      dateRange: {
        start: null,
        end: null,
      },
      exporting: false,
    };
  },
  mounted() {
    this.$store.dispatch("order.order_purchase.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      purchases: "order.order_purchase.list",
    }),
  },
  methods: {
    refresh() {
      this.$store.dispatch("order.order_purchase.fetch");
    },
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterBetween = this.lodash.mapKeys(
        this.filter_between,
        function (value, key) {
          return `filter_between[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({
        ...filter,
        ...filterBetween,
      });
      this.$store.dispatch("order.order_purchase.apply-query", filterFields);
    },
    setUpdatedAtFilter({ start, end }) {
      this.filter_between["created_at"] = `${start},${end}`;
      this.filterChange();
    },
    clearFilter() {
      this.dateRange = {
        start: null,
        end: null,
      };
      this.filter_between = {};
      this.filter = {};
      this.filterChange();
    },
    exportExcel() {
      this.exporting = true
      this.$store
        .dispatch("helper.exports.export-purchase-distribution", {
          from_date: this.dateRange.start,
          to_date: this.dateRange.end,
        })
        .then(({ data, filename }) => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(data);
          link.download = filename;
          link.click();
        }).finally(() => {
          this.exporting = false
        })
    },
  },
};
</script>
